import React, { useMemo } from 'react'
import { NavBar } from './NavBar'
import SearchResultBG from 'assets/img/searchResultBG.png'
import { SearchSection } from './SearchSection'
import Results from 'views/employer/candidates/components/Results'
import Dropdown from 'components/dropdown/Dropdown'
import { PAGINATION_LIST } from 'app/utils/list'

function SearchResults({
  candidates = [],
  loading,
  filter,
  currentPage,
  onPageChange,
  candidatesCount,
  numberPerPage,
  setNumberPerPage,
  setFilter,
  setSearchKeywords,
  searchKeywords = [],
  handleChange,
  filteredValues,
  query,
  setQuery,
  key,
  setKey,
  tagMapping,
  setTagMapping,
  handleRemoveKeyword,
  suggestedTags = [],
  setSuggestedTags,
}) {
  console.log(searchKeywords)

  return (
    <div className="flex flex-col items-center justify-center min-h-full w-full bg-white">
      {/* Navbar */}
      <NavBar />

      {/* Background Image */}
      <img
        className="w-full h-[528.878px] object-cover"
        src={SearchResultBG}
        alt="Remote Recruit Search Result Page Background Image"
      />

      {/* Search Section */}
      <div className="absolute top-[15px] left-0 w-full">
        <SearchSection
          searchKeywords={searchKeywords}
          setSearchKeywords={setSearchKeywords}
          setFilter={setFilter}
          handleChange={handleChange}
          filteredValues={filteredValues}
          query={query}
          setQuery={setQuery}
          key={key}
          setKey={setKey}
          tagMapping={tagMapping}
          setTagMapping={setTagMapping}
          handleRemoveKeyword={handleRemoveKeyword}
          suggestedTags={suggestedTags}
          setSuggestedTags={setSuggestedTags}
        />
      </div>

      {/* Result Section */}
      <div className="relative rounded-[8px] bg-white top-[-110px] sm:top-[-90px] border-[0.2px] border-black shadow-[14px_41px_50px_0px_rgba(49,89,211,0.07)]">
        <div className="space-y-5 w-[100vw] sm:w-[50vw] p-[30px]">
          <div className="flex justify-between items-center">
            <div className="text-sm font-semibold text-[#808191]">
              <span className="text-[#1E3E85] font-bold text-sm">
                {candidatesCount}
              </span>{' '}
              candidates found
            </div>
            <div className="flex items-center space-x-3">
              <div className="text-[#808191] font-semibold text-sm">View</div>
              <div className="w-[70px]">
                <Dropdown
                  data={PAGINATION_LIST}
                  label=""
                  value={numberPerPage}
                  onChange={(data) => {
                    setNumberPerPage(data)
                  }}
                  className="bg-white bg-[rgba(228,228,228,0.25)] h-[50px] md:h-[58px]"
                  parentClassName="w-full"
                  labelClassName="text-[#1E3E85] font-semibold text-sm"
                />
              </div>
              <div className="text-[#808191] text-sm font-semibold">
                candidates per page
              </div>
            </div>
          </div>
          <Results
            candidates={candidates}
            loading={loading}
            hideInviteBtn={true}
            filter={filter}
            currentPage={currentPage}
            onPageChange={onPageChange}
            pageSize={numberPerPage?.name}
            count={candidatesCount}
            hideResultsForText={true}
          />
        </div>
      </div>
    </div>
  )
}

export default SearchResults
