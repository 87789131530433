import React from 'react'
import ChatBubble from './ChatBubble'
import GlobeImg from 'assets/img/globe.png'

function EarthSection() {
  return (
    <div className="flex justify-center w-full h-[550px] sm:h-[1245.7px]">
      <div className="flex flex-col items-center justify-center relative">
        <div className="flex items-center justify-center sm:w-[1065.978px] sm:h-[1065.978px]">
          <div className="opacity-[0.6] w-[356px] h-[356px] sm:w-[888px] sm:h-[888px]">
            <img src={GlobeImg} alt="Globe Image" />
          </div>
        </div>

        {/* Chat bubbles container */}
        <div className="absolute sm:top-[181px]">
          <div className="flex flex-col w-[392px] sm:w-[1065.978px] sm:h-[1065.978px]">
            <div className="flex justify-between items-center mb-[25px] sm:mb-[40px]">
              <ChatBubble
                country="United States!"
                jobPosition="Server analyst in the "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/usa_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/u.s.a_flag.png"
                highlightField="country"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
              />
              <ChatBubble
                country="Philipines"
                jobPosition="Video Editor from "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/philipines_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/u.s.a_flag.png"
                highlightField="country"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
                bgColor="#FFC628"
              />
            </div>
            <div className="flex justify-center items-center sm:mb-[40px]">
              <ChatBubble
                country="in Kenya"
                jobPosition="Full-Stack Developer "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/kenya_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/kenya_flag.png"
                highlightField="jobPosition"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
                bgColor="#F0CCFF"
              />
            </div>
            <div className="flex justify-between sm:mb-[75px]">
              <ChatBubble
                country="in India"
                jobPosition="Admin Executive "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/india_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/india_flag.png"
                highlightField="country"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
                bgColor="#7FD13B"
              />
              <ChatBubble
                country=""
                jobPosition="Product Designer from Bangladesh"
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/bangladesh_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/bangladesh_flag.png"
                highlightField="jobPosition"
                highlightStyle={{ fontWeight: 'bold', color: '#fff' }}
                bgColor="#2C4BF9"
              />
            </div>
            <div className="flex items-center justify-center">
              <p className="w-[296px] sm:w-auto sm:self-stretch text-white text-center font-manrope text-[24px] sm:text-[44px] not-italic font-light leading-normal mb-[5.9px] sm:mb-[16px]">
                Hire A{' '}
                <span className="text-[#FFC628] font-semibold">
                  World of Talent,
                </span>{' '}
                Beyond Borders
              </p>
            </div>
            <p className=" text-[#DBE6FF] text-center font-manrope text-[8.854px] sm:text-[24px] not-italic font-normal leading-[10.625px] sm:leading-[28.8px] sm:mb-[65px]">
              We’re a global community, connecting experts from every field and
              every <br /> corner of the world. Whether you need a developer in
              Berlin, a designer in <br /> Lagos, or a teacher in Tokyo, our
              network brings skills and passion <br /> together—ready to make an
              impact for you.
            </p>
            <div className="flex justify-between sm:mb-[40px]">
              <ChatBubble
                country=""
                jobPosition="FilmMaker in Spain"
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/spain_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/spain_flag.png"
                highlightField="jobPosition"
                highlightStyle={{ fontWeight: 'bold', color: '#fff' }}
                bgColor="#D7263D"
              />
              <ChatBubble
                country=""
                jobPosition="Web and Mobile Game Development  in China"
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/china_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/china_flag.png"
                highlightField="jobPosition"
                highlightStyle={{ fontWeight: 'bold', color: '#fff' }}
                bgColor="#A755FF"
              />
            </div>
            <div className="flex justify-center items-center mt-[20px] sm:mt-0 sm:mb-[40px]">
              <ChatBubble
                country="in UAE"
                jobPosition="Logistics Coordinator "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/uae_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/uae_flag.png"
                highlightField="country"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
                bgColor="#00CED1"
              />
            </div>
            <div className="flex justify-between mt-[25px] sm:mt-0">
              <ChatBubble
                country="in Ethiopia"
                jobPosition="Medical Interpreter "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/ethipia_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/ethiopia_flag.png"
                highlightField="country"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
                bgColor="#FFD166"
              />
              <ChatBubble
                country="in United Kingdom"
                jobPosition="Customer Advisor "
                avatar="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/uk_earth.png"
                flag="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/uk_flag.png"
                highlightField="country"
                highlightStyle={{ fontWeight: 'bold', color: '#000' }}
                bgColor="#F9B3D1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EarthSection
