import Button from 'components/Button'
import SearchTag from './SearchTag'
import SuggestedTag from './SuggestedTag'
import { Combobox, Transition } from '@headlessui/react'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
// Custom hooks
import { BackIcon, CheckedCircleIcon } from 'components/Icons'

export function SearchSection({
  setFilter,
  setSearchKeywords,
  searchKeywords = [],
  handleChange,
  filteredValues,
  query,
  setQuery,
  key,
  setKey,
  tagMapping,
  handleRemoveKeyword,
  suggestedTags = [],
  setSuggestedTags,
}) {
  // console.log('setFilter', setFilter, searchKeywords)
  // let suggestedTaggs = [
  //   'Remote',
  //   'Full Stack',
  //   'Frontend',
  //   'Backend',
  //   'DevOps',
  //   'UI/UX',
  //   'Product',
  //   'Marketing',
  //   'Sales',
  //   'Customer Service',
  // ]

  const getLabelById = (id) => {
    const item = tagMapping[id]
    if (item && item.name) {
      return item.name
    }
    return id.startsWith('skill-') ? id.replace('skill-', '') : id
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  const addSuggestedTag = (tag) => {
    const modifiedTag = `skill-${tag}`
    setSearchKeywords([...searchKeywords, modifiedTag])
    const newArr = suggestedTags.filter((item) => item !== tag)
    setSuggestedTags(newArr)
  }

  return (
    <section className="pt-32 pb-16 text-center relative w-full bg-opacity-50">
      <h1 className="text-[#FFC628] font-manrope text-[36px] sm:text-[56px] not-italic font-semibold leading-[35.64px] sm:leading-[55.44px] mb-[6px] sm:mb-[20px]">
        <span className="block sm:inline whitespace-nowrap">
          Global recruitment{' '}
        </span>
        <span className="block sm:inline text-white font-light">
          meets video
        </span>
      </h1>

      <p className="text-[#ABC3FA] text-center font-manrope text-[9.6px] sm:text-[19.073px] not-italic font-normal leading-normal mb-[6px] sm:mb-[20px]">
        Explore the world's limitless talent pool with video-based hiring
      </p>
      <div className="flex flex-col items-center justify-center">
        <Combobox value={key} onChange={handleChange}>
          <div className="flex h-[45px] sm:h-[59px] w-[315px] sm:w-[587.4px] px-[22px] py-[13px] items-center rounded-[8px] bg-white">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-[13.845px] h-[13.845px] sm:w-[25px] sm:h-[25px]"
                viewBox="0 0 25 25"
                fill="none"
              >
                <path
                  d="M11.7949 2.5C16.7629 2.5 20.7949 6.532 20.7949 11.5C20.7949 16.468 16.7629 20.5 11.7949 20.5C6.82692 20.5 2.79492 16.468 2.79492 11.5C2.79492 6.532 6.82692 2.5 11.7949 2.5ZM11.7949 18.5C15.6624 18.5 18.7949 15.3675 18.7949 11.5C18.7949 7.6325 15.6624 4.5 11.7949 4.5C7.92742 4.5 4.79492 7.6325 4.79492 11.5C4.79492 15.3675 7.92742 18.5 11.7949 18.5ZM20.2802 18.5711L23.1086 21.3995L21.6944 22.8137L18.866 19.9853L20.2802 18.5711Z"
                  fill="#011137"
                />
              </svg>
            </div>
            <Combobox.Input
              placeholder="Search for Candidates"
              // displayValue={(v) => v?.name}
              onChange={(event) => setQuery(event.target.value)}
              onKeyDown={handleKeyDown}
              className="focus:ring-0 focus:outline-none w-full ml-[10px] sm:h-full text-[rgba(1, 17, 55, 0.43)] font-manrope text-[11.538px] sm:text-[20px] not-italic font-light leading-normal"
              autoComplete="off"
            />
            <Button className="flex px-[16.6px] py-[5.76px] sm:px-[28.7px] sm:py-[9.98px] justify-center items-center rounded-[6.8px] bg-[#00091D] text-white font-manrope text-[12.64px] sm:text-[14.977px] not-italic font-normal leading-normal">
              Search
            </Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="relative mt-1 max-h-60 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50 w-[63vw] sm:w-[35.625rem]">
              {filteredValues?.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredValues?.map((v) => (
                  <Combobox.Option
                    key={v.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-teal-600 text-gray-900' : 'text-gray-900'
                      }`
                    }
                    value={v}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`flex items-start ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {v.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <CheckedCircleIcon />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </Combobox>
        {searchKeywords.length > 0 && (
          <div className="relative flex flex-col justify-start w-[80vw] sm:w-[587.4px] h-[80px] sm:h-[56.4px] rounded-[12px] mt-[10px] pl-[24px] pt-[0px] pr-[24px] z-20 mb-[6.93px] overflow-x-hidden overflow-y-auto">
            <div className="flex relative flex-wrap w-[80vw] sm:w-[473px] justify-start items-start gap-[4.219px] mb-[6.93px]">
              {searchKeywords.length > 0 &&
                searchKeywords.map((key, id) => (
                  <div key={id} onClick={() => handleRemoveKeyword(key)}>
                    <SearchTag name={getLabelById(key)} />
                  </div>
                ))}
              {/* <SearchTag name="Creative" />
              <SearchTag name="Animation" />
              <SearchTag name="Illustration" />
              <SearchTag name="Photoshop" />
              <SearchTag name="Marketing" />
              <SearchTag name="Designer" />
              <SearchTag name="Developer" />
              <SearchTag name="Creative" /> */}
            </div>
            <div className="flex relative flex-wrap w-[80vw] sm:w-[587.4px] justify-start items-start gap-[4.219px] mb-[0.93px]">
              {suggestedTags?.length > 0 &&
                suggestedTags?.map((key, id) => (
                  <div key={id} onClick={() => addSuggestedTag(key)}>
                    {/* <SearchTag name={getLabelById(key)} /> */}
                    <SuggestedTag name={key} />
                  </div>
                ))}
            </div>
            {/* {suggestedTags.length < 0 && (
              <>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="490"
                    height="2"
                    viewBox="0 0 490 2"
                    fill="none"
                  >
                    <path
                      d="M0.981445 1L489.018 1"
                      stroke="black"
                      strokeWidth="0.2"
                    />
                  </svg>
                </div>
                <div className="flex flex-col items-start">
                  <p className="text-[#000000] text-center font-poppins text-[14px] italic font-light leading-normal">
                    Suggested Tags:
                  </p>
                  <div className="flex w-[490px] items-center justify-start flex-wrap gap-x-[6.563px] gap-y-[4px]">
                    <SuggestedTag name="Creative" />
                    <SuggestedTag name="Design & Creative" />
                    <SuggestedTag name="Engineering & Architecture" />
                    <SuggestedTag name="Writing & Translation" />
                    <SuggestedTag name="Sales & Marketing" />
                    <SuggestedTag name="Legal" />
                    <SuggestedTag name="Admin & Customer Support" />
                    <SuggestedTag name="Finance & Accounting" />
                  </div>
                </div>
              </>
            )} */}
          </div>
        )}
      </div>
      <div className="flex relative left-20 mt-1 sm:mt-6">
        <div>
          {searchKeywords.length > 0 && (
            <div
              onClick={() => setSearchKeywords([])}
              className="flex items-center space-x-4.5 cursor-pointer mt-8"
            >
              <BackIcon />
              <div className="text-[#52B4DA] leading-8 text-2xl font-medium">
                Go back
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}
