import React, { useState } from 'react'
import CandidateVideoPreviewCard from './CandidateVideoPreviewCard'
import { removeExtraSpaces } from 'app/utils/sanitizeText'
import SkillTagCard from './SkillTagCard'
import { useNavigate } from 'react-router-dom'
import { truncate } from 'lodash'

function CandidateModal({ closeModal, data, handleInvite }) {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleBio = () => {
    setIsExpanded(!isExpanded)
  }
  const navigate = useNavigate()

  function truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + '...'
    } else {
      return str
    }
  }

  const moveToCandidateDetail = (e) => {
    // Check if the event is triggered by a right-click or a modifier key
    if (e.type === 'click' && (e.ctrlKey || e.metaKey || e.button === 1)) {
      // Open in a new tab
      window.open(`/employer/profile/${data?.id}`, '_blank')
    } else {
      // Default behavior: navigate in the same tab
      navigate(`/employer/profile/${data?.id}`)
      closeModal()
    }
  }

  const handleContextMenu = (event) => {
    // Prevent the default right-click menu from appearing
    event.preventDefault()

    // Open the component (or a specific path) in a new tab
    window.open(`/employer/profile/${data.id}`, '_blank')
  }

  const sanitisedBio = removeExtraSpaces(data?.bio)

  return (
    <div className="flex relative px-[48px] py-[20px] sm:py-[64px] items-start gap-[10px] bg-white sm:w-[798.619px] rounded-[8px]">
      <div
        onClick={closeModal}
        className="flex p-[17.743px] sm:p-[21.029px] justify-center items-center gap-[4.571px] sm:gap-[7.698px] sm:bg-[#EDEFEB] bg-white rounded-[45.709px] sm:rounded-[76.984px] absolute right-[7px] sm:right-[30.619px] top-[-60px] sm:top-[26px] z-50"
        style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="w-[13px] sm:w-[22px] h-[13px] sm:h-[22px] "
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            d="M17.4016 4.60156L4.60156 17.4016M4.60156 4.60156L17.4016 17.4016"
            stroke="#141B34"
            stroke-width="1.37143"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div className="flex flex-col sm:flex-row justify-center sm:items-center gap-[20px] sm:gap-[38px]  ">
        <div className="flex justify-center items-center">
          <div className="max-w-[247px] sm:min-w-[295px] h-[297px] sm:h-[381px]">
            <CandidateVideoPreviewCard data={data} />
          </div>
        </div>
        <div className="flex flex-col flex-grow justify-center sm:items-start w-full min-h-[328px] ">
          <div
            onClick={moveToCandidateDetail}
            className="text-[#1E3E85] text-center font-inter text-[16px] sm:text-[20px] not-italic font-medium cursor-pointer "
          >
            {data.name}
          </div>
          <div className="text-[#11142D] text-center font-poppins text-[23.426px] sm:text-[29.11px] not-italic font-semibold ">
            {truncateString(data.job_title, 20)}
          </div>
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: isExpanded
                  ? sanitisedBio
                  : truncateString(sanitisedBio, 200),
              }}
              className="w-[291.321px] text-[#808191] sm:text-start font-inter text-[11.267px] sm:text-[14px] not-italic font-normal leading-[19.314px] sm:leading-[24px]"
            />
            {sanitisedBio.length > 200 && (
              <span
                className="font-semibold font-inter text-[#52B4DA] text-[11px] sm:text-[14px] not-italic leading-[19.314px] sm:leading-[24px] underline cursor-pointer"
                onClick={toggleBio}
              >
                {isExpanded ? 'See Less' : 'See More'}
              </span>
            )}
          </div>
          <p className="text-[#1E3E85] font-inter text-[11.267px] sm:text-[14px] not-italic font-semibold leading-[19.314px] mt-[12.88px] ">
            Skill Tags:
          </p>
          <div className="flex flex-wrap gap-[11.203px] ">
            {data.skills.slice(0, 3).map((v) => (
              <SkillTagCard name={v} />
            ))}
          </div>
          <p className="text-[#1E3E85] font-inter text-[11.267px] sm:text-[14px] not-italic font-semibold leading-[19.314px] mt-[12.88px] ">
            Pay:
          </p>
          <div className="flex gap-[8px] sm:flex-row">
            {/* Hourly Rate Card */}
            {data.hourly_rate !== 0 && (
              <div className="flex flex-col items-center justify-center gap-[8.048px] sm:gap-[10px] w-[127.151px] sm:w-[158px] px-[19.3px] sm:px-[24px] py-[9.66px] sm:py-[12px] rounded-[6.438px] sm:rounded-[8px] bg-[#F7F8FC] mb-[12.87px] sm:mb-[16px] border-[1.528px] border-[#E0E5F9] ">
                <p className="text-[#52B4DA] text-center font-inter text-[12.876px] sm:text-[16px] not-italic font-bold ">
                  ${data.hourly_rate}
                </p>
                <p className="text-[#808191] text-center font-inter text-[10.952px] sm:text-[13.609px] not-italic font-medium leading-[16.428px] sm:leading-[20.414px] ">
                  per hour
                </p>
              </div>
            )}

            {/* Annual Rate Card */}
            {data.annual_rate !== 0 && (
              <div className="flex flex-col items-center justify-center gap-[8.048px] sm:gap-[10px] w-[127.151px] sm:w-[158px] px-[19.3px] sm:px-[24px] py-[9.66px] sm:py-[12px] rounded-[6.438px] sm:rounded-[8px] bg-[#F7F8FC] mb-[12.87px] sm:mb-[16px] border-[1.528px] border-[#E0E5F9] ">
                <p className="text-[#52B4DA] text-center font-inter text-[12.876px] sm:text-[16px] not-italic font-bold ">
                  ${data.annual_rate}
                </p>
                <p className="text-[#808191] text-center font-inter text-[10.952px] sm:text-[13.609px] not-italic font-medium leading-[16.428px] sm:leading-[20.414px] ">
                  Annual
                </p>
              </div>
            )}

            {/* nEGOTIABLE Rate Card */}
            {data.annual_rate === 0 && data.hourly_rate === 0 && (
              <div className="flex flex-col items-center justify-center gap-[8.048px] sm:gap-[10px] w-[127.151px] sm:w-[158px] px-[19.3px] sm:px-[24px] py-[9.66px] sm:py-[12px] rounded-[6.438px] sm:rounded-[8px] bg-[#F7F8FC] mb-[12.87px] sm:mb-[16px] border-[1.528px] border-[#E0E5F9] ">
                <p className="text-[#52B4DA] text-center font-inter text-[12.876px] sm:text-[16px] not-italic font-bold ">
                  {/* ${data.annual_rate} */}
                  Negotiable
                </p>
                <p className="text-[#808191] text-center font-inter text-[10.952px] sm:text-[13.609px] not-italic font-medium leading-[16.428px] sm:leading-[20.414px] ">
                  {/* Negotiable */}
                </p>
              </div>
            )}
          </div>

          <div className="flex gap-[16px] w-full ">
            <div className="flex bg-[#52B4DA] justify-center items-center gap-[7px] h-[35px] sm:h-[40px] px-[43.057px] sm:px-[48px] py-[10.764px] sm:py-[12px] rounded-[6px] ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <circle cx="7.11938" cy="6.51562" r="6.5" fill="white" />
                <path
                  d="M6.61938 4.01562H7.61938V9.01562H6.61938V4.01562Z"
                  fill="#52B4DA"
                />
                <path
                  d="M4.61938 6.01562H9.61938V7.01562H4.61938V6.01562Z"
                  fill="#52B4DA"
                />
              </svg>
              <p
                onClick={() => handleInvite(data.id)}
                className="text-white font-poppins text-[14px] not-italic font-medium cursor-pointer "
              >
                Invite
              </p>
            </div>
            <div
              onContextMenu={handleContextMenu}
              onClick={moveToCandidateDetail}
              className="flex w-[139.038px] sm:w-[155px] h-[35.881px] sm:h-[40px]  flex-col justify-center items-center gap-[7px] sm:gap-[10px] rounded-[6px] border-[1px] border-[#1E3E85] cursor-pointer "
            >
              <p className="text-[#1E3E85] font-poppins text-[14px] not-italic font-medium  ">
                View full Profile
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CandidateModal
