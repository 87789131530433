//packages
import { Combobox, Transition } from '@headlessui/react'
import clsx from 'clsx'
import { Fragment, useState } from 'react'

//custom components
import { CheckedCircleIcon, SearchIcon } from 'components/Icons'

//assets
import AdjustIcon from 'assets/images/adjust-icon.png'
import Button from 'components/Button'

export default function SearchAutoComplete({
  label,
  value,
  onChange,
  data = [],
  error = '',
  errorClassName = 'text-xs',
  disabled = false,
  placeholder = 'Search...',
  inputClassName = 'bg-[rgba(228,228,228,0.25)] px-16',
  onShowFilterModal,
}) {
  const [query, setQuery] = useState('')
  const filterdValues =
    query === ''
      ? data
      : data.filter((v) =>
          v.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .startsWith(query.toLowerCase().replace(/\s+/g, ''))
        )

  return (
    <div className="relative space-y-3">
      {label && (
        <label className={clsx('block text-xs', 'text-gray-300')}>
          {label}
        </label>
      )}
      <Combobox disabled={disabled} value={value} onChange={onChange}>
        <div className="flex h-[45px] sm:h-[59px] w-[315px] sm:w-[815px] px-[22px] py-[13px] items-center rounded-[8px] bg-white">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-[13.845px] h-[13.845px] sm:w-[25px] sm:h-[25px]"
              viewBox="0 0 25 25"
              fill="none"
            >
              <path
                d="M11.7949 2.5C16.7629 2.5 20.7949 6.532 20.7949 11.5C20.7949 16.468 16.7629 20.5 11.7949 20.5C6.82692 20.5 2.79492 16.468 2.79492 11.5C2.79492 6.532 6.82692 2.5 11.7949 2.5ZM11.7949 18.5C15.6624 18.5 18.7949 15.3675 18.7949 11.5C18.7949 7.6325 15.6624 4.5 11.7949 4.5C7.92742 4.5 4.79492 7.6325 4.79492 11.5C4.79492 15.3675 7.92742 18.5 11.7949 18.5ZM20.2802 18.5711L23.1086 21.3995L21.6944 22.8137L18.866 19.9853L20.2802 18.5711Z"
                fill="#011137"
              />
            </svg>
          </div>
          <Combobox.Input
            placeholder={placeholder}
            // displayValue={(v) => v?.name}
            onChange={(event) => setQuery(event.target.value)}
            // onKeyDown={handleKeyDown}
            className="focus:ring-0 focus:outline-none w-full ml-[10px] sm:h-full text-[rgba(1, 17, 55, 0.43)] font-manrope text-[11.538px] sm:text-[20px] not-italic font-light leading-normal"
            autoComplete="off"
          />
          <Button className="flex px-[16.6px] py-[5.76px] sm:px-[28.7px] sm:py-[9.98px] justify-center items-center rounded-[6.8px] bg-[#FFC628] text-white font-manrope text-[12.64px] sm:text-[14.977px] not-italic font-normal leading-normal">
            Search
          </Button>
        </div>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={() => setQuery('')}
        >
          <Combobox.Options className="relative mt-1 max-h-60 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50 w-[63vw] sm:w-[35.625rem]">
            {filterdValues?.length === 0 && query !== '' ? (
              <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                Nothing found.
              </div>
            ) : (
              filterdValues?.map((v) => (
                <Combobox.Option
                  key={v.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-teal-600 text-gray-900' : 'text-gray-900'
                    }`
                  }
                  value={v}
                >
                  {({ selected, active }) => (
                    <>
                      <span
                        className={`flex items-start ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {v.name}
                      </span>
                      {selected ? (
                        <span
                          className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                            active ? 'text-white' : 'text-teal-600'
                          }`}
                        >
                          <CheckedCircleIcon />
                        </span>
                      ) : null}
                    </>
                  )}
                </Combobox.Option>
              ))
            )}
          </Combobox.Options>
        </Transition>
      </Combobox>
      {/* <Combobox disabled={disabled} value={value} onChange={onChange}>
        <div className="">
          <div className="flex space-x-[10px]">
            <div className="bg-[rgba(228,228,228,0.5)] rounded-2xl relative w-full">
              <Combobox.Input
                className={clsx(
                  'w-full border-none py-[18px] leading-5 text-gray-900 focus:ring-0 text-[14px] md:text-sm focus-visible:outline-none placeholder:text-gray-100',
                  inputClassName
                )}
                displayValue={(v) => v?.name}
                onChange={(event) => setQuery(event.target.value)}
                placeholder={placeholder}
                autoComplete="off"
              />
              <SearchIcon className="text-[#200E32] absolute top-[15px] md:top-[18px] left-[22px] md:left-[27px]" />
            </div>
            <div
              className="flex-none flex md:hidden justify-center items-center"
              onClick={onShowFilterModal}
            >
              <img
                className="w-[30px] h-[30px]"
                src={AdjustIcon}
                alt="adjust-icon"
              />
            </div>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-50">
              {filterdValues.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filterdValues.map((v) => (
                  <Combobox.Option
                    key={v.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-teal-600 text-gray-900' : 'text-gray-900'
                      }`
                    }
                    value={v}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {v?.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-teal-600'
                            }`}
                          >
                            <CheckedCircleIcon />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox> */}
      {error && (
        <div
          className={clsx(
            'text-red-300 text-[12px] md:text-xs font-semibold mt-1.5',
            errorClassName
          )}
        >
          {error}
        </div>
      )}
    </div>
  )
}
