import React, { useCallback, useEffect, useState } from 'react'
import { NavBar } from './components/NavBar'
import HeroSection from './components/HeroSection'
import Footer from './components/Footer'
import GetStartedSection from './components/GetStartedSection'
import Client from './components/Client'
import EarthSection from './components/EarthSection'
import CategorySection from './components/CategorySection'
import CarauselSection from './components/CarauselSection'
import { SearchSection } from './components/SearchSection'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchCandidates,
  getCandidates,
  getCandidatesCount,
} from 'app/store/candidateSlice'
import { PAGINATION_LIST } from 'app/utils/list'
import compareProfiles from 'app/utils/profileRank'
import SearchResults from './components/SearchResults'
import useTagList from 'hooks/useTagList'
import tagService from 'app/services/tag.service'

function LatestHomePage() {
  const [loading, setLoading] = useState(false)
  const keywordsStored =
    JSON.parse(localStorage.getItem('filter_jobs_keywords')) ?? []
  const [searchKeywords, setSearchKeywords] = useState(keywordsStored)
  const [suggestedTags, setSuggestedTags] = useState([])
  const [filter, setFilter] = useState({})
  const dispatch = useDispatch()

  const [numberPerPage, setNumberPerPage] = useState(PAGINATION_LIST[0])
  const [currentPage, setCurrentPage] = useState(() => {
    const storedPage = localStorage.getItem('can_page')
    return storedPage ? Number(storedPage) : 1
  })
  const candidates = useSelector(getCandidates)
  const candidatesCount = useSelector(getCandidatesCount)
  const abortControllerRef = React.useRef(null)

  const [query, setQuery] = useState('')
  const [key, setKey] = useState(null)
  const [tagMapping, setTagMapping] = useState({})
  const [mergedData, setMergedData] = useState([])
  const [skillsTags, , , jobTitleTags] = useTagList()

  useEffect(() => {
    const merged = [
      ...skillsTags?.map((v) => {
        const uniqueId = `skill-${v.id}`
        return { id: uniqueId, name: v.label, mainId: v.mainId }
      }),
      ...jobTitleTags?.map((v) => {
        const uniqueId = `job-${v.id}`
        return { id: uniqueId, name: v.label }
      }),
    ]

    const mapping = merged.reduce((acc, v) => {
      acc[v.id] = v
      return acc
    }, {})

    setMergedData(merged)
    setTagMapping(mapping)
  }, [skillsTags, jobTitleTags])

  const handleChange = useCallback(
    (value) => {
      setKey(value)
      setSearchKeywords([...searchKeywords, value])
      if (searchKeywords?.includes(value.id)) {
        const newKeywords = searchKeywords?.filter((v) => v !== value.id)
        setSearchKeywords(newKeywords)
      } else {
        const newKeywords = [...searchKeywords, value.id]
        setSearchKeywords(newKeywords)
      }
    },
    [searchKeywords, setSearchKeywords]
  )

  const filteredValues =
    query === ''
      ? mergedData
      : mergedData.filter((v) =>
          v.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  const handleRemoveKeyword = useCallback(
    (key) => {
      const newKeywords = searchKeywords.filter((v) => v !== key)
      setSearchKeywords(newKeywords)
    },
    [searchKeywords, setSearchKeywords]
  )

  const fetchList = useCallback(async () => {
    try {
      // Cancel any ongoing request
      if (abortControllerRef.current && !_.isEmpty(filter)) {
        abortControllerRef.current.abort()
        console.log('Aborting previous request...')
        console.log(
          'Request aborted:',
          abortControllerRef.current.signal.aborted
        )
      }

      // Create new AbortController for this request
      abortControllerRef.current = new AbortController()

      // Verify if the signal is properly set up
      // console.log('Signal is set:', abortControllerRef.current.signal)

      setLoading(true)
      let param = {
        offset: (currentPage - 1) * numberPerPage.name,
        limit: numberPerPage.name,
      }
      if (!_.isEmpty(filter)) {
        param = { ...param, filter }
      }

      if (searchKeywords.length > 0) {
        const modifiedArray = searchKeywords.map((item) =>
          item.replace(/^(skill-|job-)/, '')
        )
        param = { ...param, filter: { ...filter, skills: [...modifiedArray] } }
      }

      if (searchKeywords.length > 0) {
        const removeAutoScroll = localStorage.getItem('can_selected')
        if (removeAutoScroll) {
          localStorage.removeItem('can_selected')
        }
        console.log('Fetching candidates', param)
        await dispatch(
          fetchCandidates({
            ...param,
            signal: abortControllerRef.current.signal,
          })
        )
      }
    } catch (err) {
      console.log('debug: err', err)
    } finally {
      setLoading(false)
    }
  }, [filter, currentPage, numberPerPage])

  useEffect(() => {
    const removeAutoScroll = localStorage.getItem('can_selected')
    if (removeAutoScroll) {
      localStorage.removeItem('can_selected')
    }
    fetchList()
  }, [fetchList, currentPage])

  useEffect(() => {
    if (searchKeywords?.length === 0) {
      setFilter((prev) => {
        const temp = { ...prev }
        delete temp.skills
        return temp
      })
    } else {
      const actualKeywords = searchKeywords?.map((key) => {
        if (key.startsWith('skill-')) {
          return tagMapping[key]?.name
        } else {
          return key
        }
      })
      setFilter((prev) => ({
        ...prev,
        skills: actualKeywords,
      }))
    }
  }, [searchKeywords, setFilter, tagMapping])

  const onPageChange = (param) => {
    localStorage.setItem('prev_can_page', currentPage)
    setCurrentPage(param)
    localStorage.setItem('can_page', param)
    const elementTop = document.getElementById('results-top')
    elementTop?.scrollIntoView({ behavior: 'smooth' })
  }

  const sortedCandidates = [...candidates].sort(compareProfiles)
  // console.log(sortedCandidates)
  // console.log(candidates)

  const getRandomTags = (tagsArray, n) => {
    // Shuffle the array
    // const shuffled = tagsArray.sort(() => 0.5 - Math.random())
    // Return the first n items
    return tagsArray.slice(0, n)
  }

  useEffect(async () => {
    const lastSelectedTagID = searchKeywords[searchKeywords.length - 1]
    const lastSelectedTag = tagMapping[lastSelectedTagID]
    const lastSelectedTagMainID = lastSelectedTag?.mainId
    // console.log(lastSelectedTag?.mainId)
    if (searchKeywords.length > 0) {
      const suggestedTags = await tagService.getSuggestedTags(
        lastSelectedTagMainID
      )
      // Randomly select 6 tags
      const randomlySelectedTags = getRandomTags(suggestedTags.data, 6)

      // Exclude the selected tag from the data
      // const filteredTags = randomlySelectedTags?.filter(
      //   (tag) =>
      //     tag.name !== lastSelectedTag && !searchKeywords.includes(tag.name)
      // )

      const filteredTags = randomlySelectedTags?.filter((tag) => {
        const normalizedSearchTags = searchKeywords.map((searchTag) =>
          searchTag.replace('skill-', '')
        )
        return tag !== lastSelectedTag && !normalizedSearchTags.includes(tag)
      })

      console.log('suggestedTags', suggestedTags.data)
      console.log('randomlySelectedTags', filteredTags)
      setSuggestedTags(filteredTags)
    }

    // const getLabelById = (id) => {
    //   const item = tagMapping[id]
    //   return item ? item.name : ''
    // }
  }, [searchKeywords])

  return (
    // <div className="bg-[#00113C]">
    <div className="bg-[#00091D] overflow-hidden">
      {searchKeywords.length !== 0 ? (
        <>
          {!loading && candidates.length === 0 ? (
            <>
              <NavBar />
              <div
                className="flex justify-center items-center gap-[8.026px] absolute h-[570px] max-w-full top-[-218px] sm:top-[-148px] opacity-[0.2] overflow-hidden"
                // style={{ filter: 'blur(5px)' }}
              >
                <HeroSection />
              </div>
              <div
                className="hidden sm:block absolute w-3/4 h-[361px] sm:w-[1106px] sm:h-[942.706px] rounded-[1106px] bg-[#002477] ml-[28px] sm:ml-[62px] mr-auto"
                style={{ filter: 'blur(82.432px)' }}
              ></div>
              <SearchSection
                searchKeywords={searchKeywords}
                setSearchKeywords={setSearchKeywords}
                setFilter={setFilter}
                handleChange={handleChange}
                filteredValues={filteredValues}
                query={query}
                setQuery={setQuery}
                key={key}
                setKey={setKey}
                tagMapping={tagMapping}
                setTagMapping={setTagMapping}
                handleRemoveKeyword={handleRemoveKeyword}
                suggestedTags={suggestedTags}
                setSuggestedTags={setSuggestedTags}
              />
              <HeroSection />
              <CategorySection />
              <CarauselSection />
              <EarthSection />
              <Client />
              <GetStartedSection />
              <Footer />
            </>
          ) : (
            <SearchResults
              candidates={sortedCandidates}
              loading={loading}
              hideInviteBtn={true}
              filter={filter}
              currentPage={currentPage}
              onPageChange={onPageChange}
              pageSize={numberPerPage.name}
              numberPerPage={numberPerPage}
              setNumberPerPage={setNumberPerPage}
              candidatesCount={candidatesCount}
              hideResultsForText={true}
              searchKeywords={searchKeywords}
              setSearchKeywords={setSearchKeywords}
              setFilter={setFilter}
              handleChange={handleChange}
              filteredValues={filteredValues}
              query={query}
              setQuery={setQuery}
              key={key}
              setKey={setKey}
              tagMapping={tagMapping}
              setTagMapping={setTagMapping}
              handleRemoveKeyword={handleRemoveKeyword}
              suggestedTags={suggestedTags}
              setSuggestedTags={setSuggestedTags}
            />
          )}
        </>
      ) : (
        <>
          <NavBar />
          <div
            className="flex justify-center items-center gap-[8.026px] absolute h-[570px] w-full top-[-218px] sm:top-[-148px] opacity-[0.2]"
            // style={{ filter: 'blur(5px)' }}
          >
            <HeroSection />
          </div>
          <div
            className="hidden sm:block absolute w-3/4 h-[361px] sm:w-[1106px] sm:h-[942.706px] rounded-[1106px] bg-[#002477] ml-[28px] sm:ml-[62px] mr-auto overflow-hidden"
            style={{ filter: 'blur(82.432px)' }}
          ></div>
          <SearchSection
            searchKeywords={searchKeywords}
            setSearchKeywords={setSearchKeywords}
            setFilter={setFilter}
            handleChange={handleChange}
            filteredValues={filteredValues}
            query={query}
            setQuery={setQuery}
            key={key}
            setKey={setKey}
            tagMapping={tagMapping}
            setTagMapping={setTagMapping}
            handleRemoveKeyword={handleRemoveKeyword}
          />
          <HeroSection />
          <CategorySection
            searchKeywords={searchKeywords}
            setSearchKeywords={setSearchKeywords}
            setFilter={setFilter}
            filter={filter}
          />
          <CarauselSection />
          <EarthSection />
          <Client />
          <GetStartedSection />
          <Footer />
        </>
      )}
    </div>
  )
}

export default LatestHomePage
