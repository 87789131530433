//packages
import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment } from 'react'
import ReactPlayer from 'react-player'

//custom components
import { CrossIcon } from 'components/Icons'
import { RotateIcon } from 'components/Icons'
import CandidateModal from 'views/employer/candidates/components/CandidateModal'

export default function CandidateDialog({
  isOpen,
  closeModal,
  data,
  handleInvite,
}) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto bg-[#1B1D21] bg-opacity-80">
          <div className="flex min-h-full items-center justify-center p-4 text-center relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full rounded-2xl max-w-[790px] transform overflow-visible text-left align-middle transition-all">
                <CandidateModal
                  closeModal={closeModal}
                  data={data}
                  handleInvite={handleInvite}
                />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
