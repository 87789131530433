import React from 'react'
import VideoCard from './VideoCard'
import TextCard from './TextCard'
import whyRRCard from 'assets/img/why.png'
import TalentFromEveryCornerCard from './TalentFromEveryCornerCard'
import InversedTextCard from './InversedTextCard'
import TheRightFitTextCard from './TheRightFitTextCard'

export default function HeroSection() {
  return (
    <div className="flex items-center justify-center gap-[9.37px] relative top-0 h-auto mx-6 mb-[62.87px] sm:mb-[67px] mt-[57.73px] z-0 overflow-hidden">
      <div className="">
        <VideoCard
          img="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/loyidIMG.png"
          name="Loyid Tsige"
          job="Sales Dev Rep"
          location="Ethiopia"
          vid="https://remote-recruit-users-bucket-qwerty-dev.s3.us-east-1.amazonaws.com/loyid-optimised.mp4"
        />
        <TextCard
          upperText="- Hiring Now"
          lowerText="Without Borders"
          color="#FFC628"
          upperTextColor="#503047"
        />
        <VideoCard
          img="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/tanveerIMG.png"
          name="Tanveer kaur banwait"
          job="Sales Field"
          location="India"
          vid="https://remote-recruit-users-bucket-qwerty-dev.s3.us-east-1.amazonaws.com/tanveer-optimised.mp4"
        />
      </div>
      <div>
        <TheRightFitTextCard
          color="#A755FF"
          lowerText="The Right Fit"
          upperText="- Wherever talent is, Find"
        />
        <VideoCard
          img="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/juliusImg.png"
          name="Julius Nyule"
          job="IOS Developer"
          location="Kenya"
          vid="https://remote-recruit-users-bucket-qwerty-dev.s3.us-east-1.amazonaws.com/julius-optimised.mp4"
        />
        <TalentFromEveryCornerCard
          color="#503047"
          lowerText="Talent from Every Corner"
        />
      </div>
      <div>
        <VideoCard
          img="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/hellenImg.png"
          name="Hellen Cortes"
          job="Customer Service"
          location="Costa Rica"
          vid="https://remote-recruit-users-bucket-qwerty-dev.s3.us-east-1.amazonaws.com/hellen-optimised.mp4"
        />
        <InversedTextCard
          color="#FF8E47"
          lowerText="Skip Boring Resumes"
          upperText="Show Them"
          upperTextColor="#FFF6FA"
        />
        <VideoCard
          img="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/serenaImg.png"
          name="Serena Caravela"
          job="Sales"
          location="U.S.A"
          vid="https://remote-recruit-users-bucket-qwerty-dev.s3.us-east-1.amazonaws.com/serena-optimised.mp4"
        />
      </div>
      <div>
        <TextCard
          upperText="- Watch Candidates"
          lowerText="Pre-Screen Candidates"
          color="#003BC5"
          upperTextColor="#D3C1CE"
        />
        <VideoCard
          img="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/leulImg.png"
          name="Leul Gizaw Abebe"
          job="Engineer"
          location="Ethiopia"
          vid="https://remote-recruit-users-bucket-qwerty-dev.s3.us-east-1.amazonaws.com/leul-optimised.mp4"
        />
        <TextCard
          upperText="- With Remote Recruit"
          lowerText="Talent is Talent"
          color="#F05673"
          upperTextColor="#802265"
        />
      </div>
      <div>
        <VideoCard
          img="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/pabloImg.png"
          name="Pablo García-Rollán"
          job="Film Maker"
          location="Spain"
          vid="https://remote-recruit-users-bucket-qwerty-dev.s3.us-east-1.amazonaws.com/pablo-optimised.mp4"
        />
        <div className="mt-[9.89px]">
          <img src={whyRRCard} alt="Why Remote Recruit Card" />
        </div>
        {/* <TextCard /> */}
        <VideoCard
          img="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/hazelImg.png"
          name="Hazel Mushore"
          job="Sales Prof"
          location="Zimbabwe"
          vid="https://remote-recruit-users-bucket-qwerty-dev.s3.us-east-1.amazonaws.com/hazel-optimised.mp4"
        />
      </div>
      <div>
        <TextCard
          upperText="- Watch Candidates"
          lowerText="Pre-Screen Candidates"
          color="#EC7B34"
          upperTextColor="#D3C1CE"
        />
        <VideoCard
          img="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/anaImg.png"
          name="Ana marzia"
          job="Cold Calling"
          location="Bangladesh"
          vid="https://remote-recruit-users-bucket-qwerty-dev.s3.us-east-1.amazonaws.com/ana-optimised.mp4"
        />
        <TextCard
          upperText="- With Remote Recruit"
          lowerText="Talent is Talent"
          color="#A04EF8"
          upperTextColor="#503047"
        />
      </div>
    </div>
  )
}
