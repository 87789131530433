//packages
import { getNames } from 'country-list'
import languageNameMap from 'language-name-map/map'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'

//custom components
import Checkbox from 'components/Checkbox/Checkbox'
import TextField from 'components/Input/TextField'
import AutoComplete from 'components/Select/AutoComplete'
import TagInput from 'components/Input/TagInput'

//custom hooks
import useTagList from 'hooks/useTagList'
import NumberInput from 'components/Input/NumberInput'

export default function FilterBar({
  setFilter,
  setCurrentPage,
  disabled,
  onReset,
}) {
  // this is for filters that are saved
  const languageStored =
    JSON.parse(localStorage.getItem('filter_candidates_language')) ?? []
  const locationsStored =
    JSON.parse(localStorage.getItem('filter_candidates_location')) ?? []
  const maxHourlyRateStored =
    localStorage.getItem('filter_candidates_maxHourlyRate') ?? ''
  const maxAnnualRateStored =
    localStorage.getItem('filter_candidates_maxAnnualRate') ?? ''

  const [languages, setLanguages] = useState(languageStored)
  const [locations, setLocations] = useState(locationsStored)
  // const [industries, setIndustries] = useState([])
  const [maxHourlyRate, setMaxHourlyRate] = useState(maxHourlyRateStored)
  const [enableHourly, setEnableHourly] = useState(
    maxHourlyRateStored !== '' ? true : false
  )

  const [maxAnnualRate, setMaxAnnualRate] = useState(maxAnnualRateStored)
  const [enableAnnual, setEnableAnnual] = useState(
    maxAnnualRateStored !== '' ? true : false
  )
  const [enableNegotiable, setEnableNegotiable] = useState(false)
  const [enableCoverLetterVideoIncluded, setEnableCoverLetterVideoIncluded] =
    useState(false)
  const [enableBoth, setEnableBoth] = useState(
    maxAnnualRateStored !== '' && maxHourlyRateStored !== '' ? true : false
  )
  const [typingTimeout, setTypingTimeout] = useState(null)

  // const [skillsTags, industryTags] = useTagList()

  useEffect(() => {
    if (languages.length === 0) {
      setFilter((prev) => {
        let temp = Object.assign({}, prev)
        delete temp['languages']
        return temp
      })
    } else {
      setFilter((prev) => {
        return {
          ...prev,
          languages: languages.map((v) => v.id),
        }
      })
      setCurrentPage(1)
      localStorage.setItem('can_page', 1)
    }
  }, [languages])

  useEffect(() => {
    if (locations.length === 0) {
      setFilter((prev) => {
        let temp = Object.assign({}, prev)
        delete temp['countries']
        return temp
      })
    } else {
      setFilter((prev) => {
        return {
          ...prev,
          countries: locations.map((v) => v.id),
        }
      })
      setCurrentPage(1)
      localStorage.setItem('can_page', 1)
    }
  }, [locations])

  useEffect(() => {
    if (!enableNegotiable) {
      setFilter((prev) => {
        let temp = Object.assign({}, prev)
        delete temp['negotiable']
        return temp
      })
    } else {
      setFilter((prev) => {
        return {
          ...prev,
          negotiable: true,
        }
      })
    }
  }, [enableNegotiable])

  // useEffect(() => {
  //   if (industries.length === 0) {
  //     setFilter((prev) => {
  //       let temp = Object.assign({}, prev)
  //       delete temp['industries']
  //       return temp
  //     })
  //   } else {
  //     setFilter((prev) => {
  //       return {
  //         ...prev,
  //         industries: industries.map((v) => v.id),
  //       }
  //     })
  //   }
  // }, [industries])

  useEffect(() => {
    // Update "Both" checkbox based on the individual checkboxes
    setEnableBoth(enableHourly && enableAnnual)
  }, [enableHourly, enableAnnual])

  useEffect(() => {
    if (enableHourly) {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          return {
            ...prev,
            hourly:
              maxHourlyRate.length >= 5
                ? Number(maxHourlyRate.replace(/,/g, ''))
                : Number(maxHourlyRate),
          }
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
      setCurrentPage(1)
      localStorage.setItem('can_page', 1)
    } else {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          let temp = Object.assign({}, prev)
          delete temp['hourly']
          return temp
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
    }
  }, [maxHourlyRate, enableHourly])

  useEffect(() => {
    if (enableAnnual) {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          return {
            ...prev,
            annual:
              maxAnnualRate.length >= 5
                ? Number(maxAnnualRate.replace(/,/g, ''))
                : Number(maxAnnualRate),
          }
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
      setCurrentPage(1)
      localStorage.setItem('can_page', 1)
    } else {
      // Clear the previous timeout to start a new one
      clearTimeout(typingTimeout)

      // Set a new timeout
      const newTypingTimeout = setTimeout(() => {
        setFilter((prev) => {
          let temp = Object.assign({}, prev)
          delete temp['annual']
          return temp
        })
      }, 3000) // 3000 milliseconds (3 seconds)

      setTypingTimeout(newTypingTimeout)
    }
  }, [maxAnnualRate, enableAnnual])

  const handleCheckboxChange = (checkboxType) => {
    if (checkboxType === 'hourly') {
      setEnableHourly(!enableHourly)
      setEnableNegotiable(false)
    } else if (checkboxType === 'annually') {
      setEnableAnnual(!enableAnnual)
      setEnableNegotiable(false)
    } else if (checkboxType === 'coverLetterVideo') {
      setEnableCoverLetterVideoIncluded(!enableCoverLetterVideoIncluded)
    }
  }

  const handleBothCheckboxChange = () => {
    setEnableBoth(!enableBoth)
    setEnableHourly(!enableBoth)
    setEnableAnnual(!enableBoth)
    if (enableNegotiable) {
      setEnableNegotiable(enableBoth)
    }
  }

  const handleNegotiableCheckboxChange = () => {
    setEnableNegotiable(!enableNegotiable)
    if (!enableNegotiable) {
      setEnableHourly(false)
      setEnableAnnual(false)
      setEnableBoth(false)
    }
  }

  return (
    <div className="w-full pl-[35px]">
      <div className="bg-white rounded-3xl shadow-md p-7">
        <div className="text-black-100 leading-6 text-lg font-medium">
          Filter
        </div>
        <p className="block text-[12px] md:text-sm text-black-100 font-inter font-bold leading-5 mt-6">
          Pay
        </p>
        <div className="space-y-[30px] mt-5">
          <div className="space-y-[20px]">
            <Checkbox
              variant="primary"
              label="Hourly"
              value={enableHourly}
              onChange={() => handleCheckboxChange('hourly')}
              // disabled={disabled}
            />
            <div className="space-y-2.5">
              <NumberInput
                placeholder=""
                value={maxHourlyRate}
                prefix="$"
                suffix="USD"
                onChange={(e) => {
                  setEnableHourly(e.target.value.length > 0)
                  setMaxHourlyRate(e.target.value)
                  localStorage.setItem(
                    'filter_candidates_maxHourlyRate',
                    e.target.value
                  )
                }}
                // type="number"
                disabled={disabled}
              />
              <p className="font-inter font-medium leading-4 text-xs text-gray-300">
                Max Hourly Rate
              </p>
            </div>
          </div>
          <div className="space-y-[20px]">
            <Checkbox
              variant="primary"
              label="Annual Salary"
              value={enableAnnual}
              onChange={() => handleCheckboxChange('annually')}
              disabled={disabled}
            />
            <div className="space-y-2.5">
              <NumberInput
                value={maxAnnualRate}
                prefix="$"
                suffix="USD"
                onChange={(e) => {
                  setEnableAnnual(e.target.value.length > 0)
                  setMaxAnnualRate(e.target.value)
                  localStorage.setItem(
                    'filter_candidates_maxAnnualRate',
                    e.target.value
                  )
                }}
                placeholder=""
                // type="number"
                disabled={disabled}
              />
              <p className="font-inter font-medium leading-4 text-xs text-gray-300">
                Max Annual Salary
              </p>
            </div>
          </div>
          <div className="space-y-[20px]">
            <Checkbox
              variant="primary"
              label="Both Hourly & Salary"
              value={enableBoth}
              onChange={handleBothCheckboxChange}
              // disabled={disabled}
            />
          </div>
          <div className="space-y-[20px]">
            <Checkbox
              variant="primary"
              label="Negotiable"
              value={enableNegotiable}
              onChange={handleNegotiableCheckboxChange}
              // disabled={disabled}
            />
          </div>
          <div className="space-y-[15px]">
            <p className="block text-[12px] md:text-sm text-black-100 font-inter font-bold leading-5">
              Language(s)
            </p>
            <TagInput
              // label="Language(s)"
              list={_.map(languageNameMap, (v, k) => {
                return {
                  id: v.name,
                  name: v.name,
                }
              })}
              placeholder="Select Languages"
              name="languages"
              id="languages"
              onChange={(value) => {
                setLanguages(value)
                localStorage.setItem(
                  'filter_candidates_language',
                  JSON.stringify(value)
                )
              }}
              value={languages}
            />
          </div>
          <div className="space-y-[15px]">
            <p className="block text-[12px] md:text-sm text-black-100 font-inter font-bold leading-5">
              Countries:
            </p>
            <TagInput
              // label="Language(s)"
              list={_.map(getNames(), (v, k) => {
                return {
                  id: v,
                  name: v,
                }
              })}
              placeholder="Select Countries"
              name="countries"
              id="countries"
              onChange={(value) => {
                setLocations(value)
                localStorage.setItem(
                  'filter_candidates_location',
                  JSON.stringify(value)
                )
              }}
              value={locations}
            />
          </div>
          {/* <div className="space-y-[20px]">
            <Checkbox
              variant="primary"
              label="Cover Letter Video Included"
              value={enableCoverLetterVideoIncluded}
              onChange={() => handleCheckboxChange('coverLetterVideo')}
              // disabled={disabled}
            />
          </div> */}
        </div>
        <div
          onClick={() => {
            setEnableAnnual(false)
            setEnableHourly(false)
            setEnableNegotiable(false)
            setEnableCoverLetterVideoIncluded(false)
            setMaxAnnualRate('')
            setMaxHourlyRate('')
            // setIndustries([])
            setLocations([])
            setLanguages([])
            onReset()

            localStorage.removeItem('filter_candidates_language')
            localStorage.removeItem('filter_candidates_location')
            localStorage.removeItem('filter_candidates_maxHourlyRate')
            localStorage.removeItem('filter_candidates_maxAnnualRate')
          }}
          className="text-gray-100 leading-5 text-[14px] md:text-sm font-bold mt-[40px] cursor-pointer"
        >
          Reset all filters
        </div>
      </div>
    </div>
  )
}
