import React from 'react'
import { useMediaQuery } from 'react-responsive'

import JobIcon from 'assets/img/jobIcon.png'

function VideoCard({ name, job, location, vid, img }) {
  const isMobile = useMediaQuery({ maxWidth: 639 })

  return (
    <div className="relative w-[124.916px] h-[161.541px] sm:w-[194px] sm:h-[251px] mt-[8.125px]">
      {/* Video Section */}
      {isMobile ? (
        <img
          src={img}
          alt={`${name} speaking`}
          className="w-full h-full object-cover rounded-[5.232px] sm:rounded-[8.125px]"
        />
      ) : (
        <video
          className="w-full h-full object-cover rounded-[5.232px] sm:rounded-[8.125px]"
          src={vid}
          poster={img}
          autoPlay
          loop
          muted
        />
      )}
      {/* Overlay Content */}
      <div className="absolute bottom-0 w-full bg-gradient-to-t from-black to-transparent p-[9px] sm:p-4 text-white">
        <div className="sm:hidden flex relative justify-center items-center mb-12">
          <img src="https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/pausebtn.png" />
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            className="max-w-[12px] max-h-[15px] "
            viewBox="0 0 12 15"
            fill="none"
          >
            <path
              d="M1.65782 0.856545C-0.582646 2.25898 -0.532846 14.0279 1.65782 14.9542C2.97947 15.5128 11.9931 10.7306 11.9931 8.13627C11.9931 5.42629 2.73905 0.179812 1.65782 0.856545Z"
              fill="white"
            />
          </svg> */}
        </div>
        <h3 className="text-white font-manrope text-[8.602px] sm:text-[13.358px] not-italic font-bold leading-normal">
          {name}
        </h3>
        <div className="flex items-center justify-between mt-1">
          {/* Role Section */}
          <div className="flex items-center gap-[2.62px] sm:gap-1">
            <img
              src={JobIcon}
              alt="Role Icon"
              className="w-[8.602px] h-[8.602px] sm:w-[9.967px] sm:h-[10.323px]"
            />
            <span className="text-white text-center font-manrope text-[5.376px] sm:text-[8.349px] not-italic font-medium leading-normal">
              {job}
            </span>
          </div>

          {/* Country Section */}
          <div className="flex items-center gap-[2.62px] sm:gap-1">
            <img
              src={`https://remote-recruit-email-temp.s3.us-east-1.amazonaws.com/${location
                ?.toLowerCase()
                .replace(/ /g, '+')}_flag.png`}
              alt="Flag Icon"
              className="w-[8.602px] h-[8.602px] sm:w-[13.358px] sm:h-[13.358px]"
            />
            <span className="text-white text-center font-manrope text-[5.365px] sm:text-[8.331px] not-italic font-medium leading-normal">
              {location}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoCard
