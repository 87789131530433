//packages
import React, { useEffect } from 'react'
import _ from 'lodash'

//custom components
import SkeletonCandidateItem from 'components/Loader/SkeletonCandidateItem'
import CandidateItem from './CandidateItem'
import Pagination from 'components/pagination'
import CandidateVideoCard from './CandidateVideoCard'
import CandidateModal from './CandidateModal'

export default function Results({
  candidates,
  loading,
  handleInvite,
  filter,
  currentPage,
  count,
  pageSize,
  onPageChange,
  hideInviteBtn,
  hideResultsForText,
}) {
  const can_selected = localStorage.getItem('can_selected')

  // if user already viewed a candidate, then go back to Results, it will auto scroll into the last click item
  useEffect(() => {
    if (!loading) {
      const element = document.getElementById(`${can_selected}`)
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [loading, can_selected])

  const onClickPagination = () => {
    const elementTop = document.getElementById('results-top')
    elementTop?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div
      className="min-w-full shadow-candiate-bar rounded-3xl bg-white p-0 md:p-0"
      id="results-top"
    >
      <div className="flex sm:space-x-1 sm:py-[20px] md:py-0 sm:px-[15px] md:px-0">
        {_.isArray(filter?.skills) &&
          filter?.skills.length > 0 &&
          !hideResultsForText && (
            <span className="text-gray-100 font-semibold text-[12px] md:text-sm space-x-3 flex items-center">
              Result for:{' '}
              <div className="space-x-3 ml-3">
                {filter?.skills.map((v, index) => (
                  <span
                    className="text-blue-100 font-semibold text-[12px] md:text-sm"
                    key={index}
                  >
                    {v} {filter.skills.length !== index + 1 ? ',' : ''}
                  </span>
                ))}
              </div>
            </span>
          )}
      </div>
      {loading ? (
        <SkeletonCandidateItem />
      ) : (
        <div>
          {/* <CandidateModal /> */}
          <div className="grid grid-cols-2 sm:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 ">
            {candidates.map((v, i) => (
              <CandidateVideoCard
                key={i}
                data={v}
                handleInvite={handleInvite}
              />

              // <CandidateItem
              //   data={v}
              //   isLast={candidates.length === i - 1}
              //   handleInvite={handleInvite}
              //   id={`res${i}`}
              //   key={i}
              //   hideInviteBtn={hideInviteBtn}
              // >
              //   {v.job_title}
              // </CandidateItem>
            ))}
          </div>
        </div>
      )}
      {!loading && (
        <div className="mt-6 mb-[44px]">
          <Pagination
            onPageChange={onPageChange}
            currentPage={currentPage}
            totalCount={count}
            pageSize={pageSize}
            toTop={onClickPagination}
          />
        </div>
      )}
    </div>
  )
}
